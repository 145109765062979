import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import qs from 'query-string'
import Header from '../components/ExternalPagesHeader'
import { Box } from '@chakra-ui/core'

// require('dotenv').config()

const URL = `${process.env.GATSBY_IFRAME_PROTOCOL}://${process.env.GATSBY_IFRAME_URL}`

const DownloadReceita = () => {
	// Adriano pediu para passar para o endereco dele dessa maneira
	// exemplos:
	// https://bpm-hom.receitamedica.com.vc/download-receita/{codProcesso}/{uuid}

	let uuid
	let codprocesso

	if (typeof window !== 'undefined') {
		const query = qs.parse(window.location.search)
		if ('codprocesso' in query) {
			codprocesso = query.codprocesso as string
		}

		if ('uuid' in query) {
			uuid = query.uuid as string
		}
	}

	return (
		<Layout>
			<SEO title='Download receita' />
			{/* <HeaderIframe /> */}
			<Header />
			{codprocesso && uuid ? (
				<Box
					as='iframe'
					w='100%'
					h='calc(100vh - 72px)'
					id='iframe-assinatura-digital'
					frameBorder='0'
					title='Iframe de Assinatura Digital'
					src={`${URL}/download-receita/${codprocesso}/${uuid}`}
					allowFullScreen
				/>
			) : (
				<div />
			)}
		</Layout>
	)
}

export default DownloadReceita
