import React from 'react'
import { navigate } from 'gatsby'
import { Box, Text, Flex } from '@chakra-ui/core'

const ExternalPagesHeader: React.FunctionComponent = () => {
	return (
		<Flex w='100%' h='45px' justify='center' align='center' bg='brand.500' py={3}>
			<Text
				fontFamily='Poppins'
				fontWeight='bold'
				fontSize='lg'
				color='white'
				cursor='pointer'
				onClick={() => navigate('/')}
			>
				receitamedica.com.vc
			</Text>
		</Flex>
	)
}

export default ExternalPagesHeader
